import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import Hero from '../components/hero'
import { BREAKPOINTS } from '../styles/cssVariables'
import Layout from '../components/layout/layout'
import SEO from '../components/layout/seo'
import Container from '../components/layout/container'
import Quote from '../components/quote'
import FeatureList from '../components/featureList'


const QuoteContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 90px;
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }
`



const FeatureListWrapper = styled.section`
  padding-top: 90px;
  padding-bottom: 70px;
  &:last-child {
    padding-bottom: calc(105px + 90px);
  }
  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }
`
const SectionContainer = styled.section`
`

const ExpertPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query ExpertQuery {
      contentfulPageSupportPackages {
        seoTitle
        seoDescription
        openGraphImage {
          title
          gatsbyImageData(layout: FULL_WIDTH, quality: 85)
        }
        hero {
          heading
          subheading
          ctaText
          ctaDestination
        
        }
        quote {
          introText {
            introText
          }
          layout
          quote {
            quote
          }
          ctaText
          ctaDestination
        }
        featureList1 {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
        featureList2 {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
        featureList3 {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
        featureList4 {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
      }
    }
  `)
  const openGraphImage = data.contentfulPageSupportPackages.openGraphImage
  const heroHeading = data.contentfulPageSupportPackages.hero.heading
  const heroSubheading = data.contentfulPageSupportPackages.hero.subheading
  const heroCtaText = data.contentfulPageSupportPackages.hero.ctaText
  const heroCtaDestination = data.contentfulPageSupportPackages.hero.ctaDestination
  const seoTitle = data?.contentfulPageSupportPackages?.seoTitle
  const seoDescription = data?.contentfulPageSupportPackages?.seoDescription

  const quoteIntroText = data.contentfulPageSupportPackages.quote?.introText
    ? data.contentfulPageSupportPackages.quote?.introText.introText
    : undefined
  const quoteLayout = data.contentfulPageSupportPackages.quote?.layout
  const quoteText = data.contentfulPageSupportPackages.quote?.quote.quote
  const quoteCtaText = data.contentfulPageSupportPackages.quote?.ctaText
  const quoteCtaDestination =
    data.contentfulPageSupportPackages.quote?.ctaDestination

  const featureList1Layout =
    data.contentfulPageSupportPackages.featureList1.layout
  const featureList1Image = data.contentfulPageSupportPackages.featureList1.image
  const featureList1Title = data.contentfulPageSupportPackages.featureList1.title
  const featureList1Cards =
    data.contentfulPageSupportPackages.featureList1.featureCards
  const featureList1CtaText =
    data.contentfulPageSupportPackages.featureList1.ctaText
  const featureList1CtaDestination =
    data.contentfulPageSupportPackages.featureList1.ctaDestination

  const featureList2Layout =
    data.contentfulPageSupportPackages.featureList2.layout
  const featureList2Image = data.contentfulPageSupportPackages.featureList2.image
  const featureList2Title = data.contentfulPageSupportPackages.featureList2.title
  const featureList2Cards =
    data.contentfulPageSupportPackages.featureList2.featureCards
  const featureList2CtaText =
    data.contentfulPageSupportPackages.featureList2.ctaText
  const featureList2CtaDestination =
    data.contentfulPageSupportPackages.featureList2.ctaDestination

  const featureList3Layout =
    data.contentfulPageSupportPackages.featureList3.layout
  const featureList3Image = data.contentfulPageSupportPackages.featureList3.image
  const featureList3Title = data.contentfulPageSupportPackages.featureList3.title
  const featureList3Cards =
    data.contentfulPageSupportPackages.featureList3.featureCards
  const featureList3CtaText =
    data.contentfulPageSupportPackages.featureList3.ctaText
  const featureList3CtaDestination =
    data.contentfulPageSupportPackages.featureList3.ctaDestination

  const featureList4Layout = data.contentfulPageSupportPackages.featureList4
    ? data.contentfulPageSupportPackages.featureList4.layout
    : undefined
  const featureList4Image = data.contentfulPageSupportPackages.featureList4
    ? data.contentfulPageSupportPackages.featureList4.image
    : undefined
  const featureList4Title = data.contentfulPageSupportPackages.featureList4
    ? data.contentfulPageSupportPackages.featureList4.title
    : data.contentfulPageSupportPackages.featureList4
  const featureList4Cards = data.contentfulPageSupportPackages.featureList4
    ? data.contentfulPageSupportPackages.featureList4.featureCards
    : undefined
  const featureList4CtaText = data.contentfulPageSupportPackages.featureList4
    ? data.contentfulPageSupportPackages.featureList4.ctaText
    : undefined
  const featureList4CtaDestination = data.contentfulPageSupportPackages
    .featureList4data
    ? data.contentfulPageSupportPackages.featureList4.ctaDestination
    : undefined

  return (
    <Layout location={location}>
      <SEO
        pageTitle={seoTitle}
        seoDescription={seoDescription}
        // ogImage={openGraphImage.gatsbyImageData.images.fallback.src}      
      />

      <Hero
        heading={heroHeading}
        subheading={heroSubheading}
      
        ctaText={heroCtaText}
        ctaDestination={heroCtaDestination}
      />
   

      {quoteText ? (
        <SectionContainer>
          <QuoteContainer width={12}>
            <Quote
              ctaDestination={quoteCtaDestination}
              ctaText={quoteCtaText}
              introText={quoteIntroText}
              layout={quoteLayout}
              quote={quoteText}
            />
        </QuoteContainer>
      </SectionContainer>
      ) : null}

      <FeatureListWrapper>
        <Container width={12}>
          <FeatureList
            accentColor="var(--color-violet)"
            ctaText={featureList1CtaText}
            ctaDestination={featureList1CtaDestination}
            featureCardArr={featureList1Cards.map((card) => [
              card.heading,
              card.body ? card.body.body : undefined,
            ])}
            heading={featureList1Title}
            image={featureList1Image}
            layout={featureList1Layout}
            offsetImage={true}
          />
        </Container>
      </FeatureListWrapper>

      <FeatureListWrapper>
        <Container width={12}>
          <FeatureList
            accentColor="var(--color-violet)"
            ctaText={featureList2CtaText}
            ctaDestination={featureList2CtaDestination}
            featureCardArr={featureList2Cards.map((card) => [
              card.heading,
              card.body ? card.body.body : undefined,
            ])}
            heading={featureList2Title}
            image={featureList2Image}
            layout={featureList2Layout}
            offsetImage={true}
          />
        </Container>
      </FeatureListWrapper>

      <FeatureListWrapper>
        <Container width={12}>
          <FeatureList
            accentColor="var(--color-violet)"
            ctaText={featureList3CtaText}
            ctaDestination={featureList3CtaDestination}
            featureCardArr={featureList3Cards.map((card) => [
              card.heading,
              card.body ? card.body.body : undefined,
            ])}
            heading={featureList3Title}
            image={featureList3Image}
            layout={featureList3Layout}
            offsetImage={true}
          />
        </Container>
      </FeatureListWrapper>

      {featureList4CtaText && featureList4CtaText && featureList4CtaText && (
        <FeatureListWrapper>
          <Container width={12}>
            <FeatureList
              accentColor="var(--color-violet)"
              ctaText={featureList4CtaText}
              ctaDestination={featureList4CtaDestination}
              featureCardArr={featureList4Cards.map((card) => [
                card.heading,
                card.body ? card.body.body : undefined,
              ])}
              heading={featureList4Title}
              image={featureList4Image}
              layout={featureList4Layout}
              offsetImage={true}
            />
          </Container>
        </FeatureListWrapper>
      )}
    </Layout>
  )
}

export default ExpertPage